import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'wwd-outsourced-development',
  templateUrl: './outsourced-development.component.html',
  styleUrls: ['./outsourced-development.component.scss']
})
export class OutsourcedDevelopmentComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
