import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'wwd-application-support',
  templateUrl: './application-support.component.html',
  styleUrls: ['./application-support.component.scss']
})
export class ApplicationSupportComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
