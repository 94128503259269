<!-- <div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Ui Path</h2>
        </div>
    </div>
</div> -->

<div class="service-details ptb-100">
    <div class="container">
        <div class="service-details-content">
            <h3 class="pageHeader">UiPath</h3>
            <img src="assets/img/uipath-silver-partner.png" alt="image" class="pageImage">
            <p>
                UiPath’s automation software allows us to automate repetitive digital tasks that are otherwise performed by people. Their tech combines emulating how humans read screens (AI Computer Vision) with APIs and give us access to prebuilt automation components that can be combined to automate routine processes. UiPath’s Automated Platform lets us create, execute, deploy, monitor, and manage processes.
            </p>
        </div>
    </div>
</div>

