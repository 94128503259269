import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'wwd-integration-middleware',
  templateUrl: './integration-middleware.component.html',
  styleUrls: ['./integration-middleware.component.scss']
})
export class IntegrationMiddlewareComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
