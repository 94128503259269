<!-- <div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Openshift</h2>
        </div>
    </div>
</div> -->

<div class="service-details ptb-100">
    <div class="container">
        <div class="service-details-image" style="text-align: left;">
            <img src="assets/img/openshift.svg" alt="image" style="max-width: 400px;">
        </div>
        <div class="service-details-content">
            <h3>Openshift</h3>
            <p>
                OpenShift comprises a family of containerization software. It’s developed by Red Hat and offers OpenShift Container Platform as its flagship product. It’s a PaaS, built around application containers powered by Docker and offers orchestration and management provided by Kubernetes. It allows us to develop and deploy applications on their cloud infrastructure. OpenShift is capable of managing applications written in many different languages like Node.js, Python, Perl, and Ruby. It is extensible which allows us to support applications written in other languages. 
            </p>
        </div>
    </div>
</div>

