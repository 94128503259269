

<div class="saas-banner">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container max-width-1290">
                <div class="row align-items-center pt-5">
                    <div class="col-lg-6 col-md-12">
                        <div class="saas-image mt-70">
                            <img src="assets/img/saas-shape/arrow.png" class="wow fadeInDown" data-wow-delay="0.6s" alt="arrow">
                            <img src="assets/img/saas-shape/box1.png" class="wow fadeInUp" data-wow-delay="0.6s" alt="box1">
                            <img src="assets/img/saas-shape/boy1.png" class="wow fadeInLeft" data-wow-delay="0.6s" alt="boy1">
                            <img src="assets/img/saas-shape/boy2.png" class="wow zoomIn" data-wow-delay="0.6s" alt="boy2">
                            <img src="assets/img/saas-shape/boy3.png" class="wow bounceIn" data-wow-delay="0.6s" alt="boy3">
                            <img src="assets/img/saas-shape/digital-screen.png" class="wow fadeInDown" data-wow-delay="0.6s" alt="digital-screen">
                            <img src="assets/img/saas-shape/filter1.png" class="wow zoomIn" data-wow-delay="0.6s" alt="filter1">
                            <img src="assets/img/saas-shape/filter2.png" class="wow fadeInUp" data-wow-delay="0.6s" alt="filter2">
                            <img src="assets/img/saas-shape/filter3.png" class="wow rotateIn" data-wow-delay="0.6s" alt="filter3">
                            <img src="assets/img/saas-shape/girl1.png" class="wow fadeInUp" data-wow-delay="0.6s" alt="girl1">
                            <img src="assets/img/saas-shape/girl2.png" class="wow zoomIn" data-wow-delay="0.6s" alt="girl2">
                            <img src="assets/img/saas-shape/monitor.png" class="wow zoomIn" data-wow-delay="0.6s" alt="monitor">
                            <!-- Main image -->
                            <img src="assets/img/saas-shape/main-image.png" class="wow zoomIn" data-wow-delay="0.6s" alt="main-image.png">
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="hero-content pl-4">
                            <h1>Designing For Tomorrow. Delivering Today</h1>
                            <p>XIB Solutions prides itself in developing cutting-edge quality software for large enterprises within record time. We cover our bases by applying long-term strategizing to everything we do, making our solutions lasting and durable. 
                            </p>
                            <p>With more than a decade’s worth of experience in developing and implementing solutions, we strive to protect existing IT infrastructures and investments by ensuring that the fast-evolving business’s needs are met swiftly. 
                            </p>
                            <p>Because of our long-term approach that makes sure that our solutions stand the test of time and remain valuable to our clients for many years after they’ve been implemented, companies like Vodacom choose to collaborate with us. 
                            </p>
                            <!-- <div class="banner-btn">
                                <div class="d-flex">
                                    <a routerLink="/contact" class="default-btn"><i class="bx bxs-hot"></i> Get Started <span></span></a>
                                    <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube"> <i class='bx bxs-right-arrow'></i> Watch Video </a>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="shape-rotate rotateme">
        <img src="assets/img/saas-shape/shape-rotate.png" alt="img">
    </div>
    <div id="particles-js"></div>
</div>

<section class="features-area pt-100 pb-70 bg-f4f6fc">
    <div class="container max-width-1290">
        <div class="section-title">
            <h2 id="what-we-do">What We Do</h2>
            <p>With industry veterans working for our team, we at XIB ensure that our multifaceted services are of the highest quality and serve to satisfy our clientele’s every need in the most efficient manner. </p>
        </div>
        <div class="wow fadeInLeft" data-wow-delay=".3s">
            <div class="image" style="text-align: center; max-height: 400px;">
                <img src="assets/img/cloud-data.png" class="howwedoit" alt="image" style="
                max-height: 400px;
                padding-bottom: 50px;
                ">
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="features-box-one wow fadeInRight" data-wow-delay=".1s">
                    <i class='bx bx-cog bg-13c4a1'></i>
                    <h3><a routerLink="/what-we-do/outsourced-development">Outsourced Development</a></h3>
                    <p>By deploying Agile development methodology, which supports flexible, rapid, and iterative work cycles, we provide our customers with the ability to scale development in a future-proof manner. We protect our customers from volatility with a planned development pipeline. We keep our technology stack up to date and adjust it on a yearly or bi-yearly basis. We pick the framework and technologies best suited to the customer’s requirements. </p>                    
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="features-box-one wow fadeInRight" data-wow-delay=".2s">
                    <i class='bx bxs-check-shield bg-6610f2'></i>
                    <h3><a routerLink="/what-we-do/integration-middleware">Integration and Middleware</a></h3>
                    <p>Our in-depth experience and our extensive history of working with integrating software services from many different industries have given us the required skills and knowledge to integrate multiple enterprise frameworks. </p>
                    
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="features-box-one wow fadeInRight" data-wow-delay=".3s">
                    <i class='bx bx-timer bg-ffb700'></i>
                    <h3><a routerLink="/what-we-do/web-applications">Web Applications</a></h3>
                    <p>With a focus on internal enterprise web applications that need to integrate with local software services, our web application development team makes designs that are simple and easy to support. We follow RIS4 concepts that include responsive design, easy integration with existing software services, stability achieved by manual and automated testing, scalability, security, and simplicity.  </p>
                    
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="features-box-one wow fadeInLeft" data-wow-delay=".4s">
                    <i class='bx bx-badge-check bg-fc3549'></i>
                    <h3><a routerLink="/what-we-do/application-support">Application Support</a></h3>
                    <p>Whether it’s onsite or remote support services, we provide L1, L2, and L3 support services that align with our customer’s problem resolution process. We can provide a help desk service as well. We support many of the solutions that we have developed as part of our application support division. We offer user support, application support, operations support, and end-user support as part of our application support division. </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="features-box-one wow fadeInLeft" data-wow-delay=".5s">
                    <i class='bx bxs-lock-open bg-00d280'></i>
                    <h3><a routerLink="/what-we-do/manual-automated-testing">Manual and Automated Testing</a></h3>
                    <p>Our manual and automated testing services include end-to-end testing and regression testing. We align our testing processes with Agile methodologies so that they can be scaled as per your needs. Our testers are experienced in testing multiple systems such as web applications to enterprise and mobile solutions. </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="features-box-one wow fadeInLeft" data-wow-delay=".6s">
                    <i class='bx bxs-droplet-half bg-ff612f'></i>
                    <h3><a routerLink="/what-we-do/consultation-mentoring">Consultation and Mentoring</a></h3>
                    <p>We offer many different consulting services that take advantage of our vast and diverse experience in software development and the process of software delivery. Many of our veteran team members are in the perfect position to offer mentorship about optimum industry practices. We utilize AngularJS (1.5/2) as our primary interface framework and Spring (Java) or NodeJS as our back-end component for our primary development stack. </p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="features-area pt-100 pb-70 bg-f4f6fc">
    <div class="container max-width-1290">
        <div class="section-title">
            <h2 id="how-we-do-it">How We Do It</h2>
            <p>We are a long-term-oriented company, applying a future-proof strategy to our engagements and partnerships that take care of our client’s needs many years into the future. Our solutions remain relevant and work optimally well into the future because of our long-term approach. </p>
        </div>
        <div class="wow fadeInLeft" data-wow-delay=".3s">
            <div class="image" style="text-align: center; max-height: 400px;">
                <img src="assets/img/project-management.png" class="howwedoit" alt="image" style="
                max-height: 400px;
                padding-bottom: 50px;
                ">
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="features-box-one wow fadeInRight" data-wow-delay=".1s">
                    <i class='bx bx-cog bg-13c4a1'></i>
                    <h3><a routerLink="/how-we-do-it/agile-development">Agile Development</a></h3>
                    <p>We use iterative and incremental software development methodologies to ensure and facilitate focused and practical processes. We have fine-tuned our development and have learned from other Agile methodologies like Extreme Programming, Feature Driven Development, Lean, Kanban, and Scrum. </p>
                    
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="features-box-one wow fadeInRight" data-wow-delay=".2s">
                    <i class='bx bxs-check-shield bg-6610f2'></i>
                    <h3><a routerLink="/how-we-do-it/development-partnership">Development Partnership</a></h3>
                    <p>We build strategies with a long-term-oriented approach and our realization of our software solutions promises consistency in terms of implementation. Having a development partner reduces the customer’s risk for day-to-day management of the development process and resource management. </p>
                    
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="features-box-one wow fadeInRight" data-wow-delay=".3s">
                    <i class='bx bx-timer bg-ffb700'></i>
                    <h3><a routerLink="/how-we-do-it/quality-assurance">Quality Assurance</a></h3>
                    <p>We make sure that our software development process follows a rigorous step-by-step quality assurance process that includes developing use cases, Functional Acceptance Testing, regression testing, and facilitating User Acceptance Testing.  </p>
                    
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="features-box-one wow fadeInLeft" data-wow-delay=".4s">
                    <i class='bx bx-badge-check bg-fc3549'></i>
                    <h3><a routerLink="/how-we-do-it/responsible-service">Responsible Service</a></h3>
                    <p>With our arduous quality assurance and our disciplined development, we minimize risks and look beyond just the immediate requirements towards the future to provide the best long-term solutions possible.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                
            </div>
        </div>
    </div>
</section>





<section class="features-area pt-100 pb-70 bg-f4f6fc">
    <div class="container max-width-1290">
        <div class="section-title">
            <h2 id="what-we-use">What We Use</h2>
            <p>Our industry-standard technology stacks are based on their popularity and their available active open-source framework. </p>
        </div>
        <div class="features-item wow fadeInRight" data-wow-delay=".1s">
            <div class="image" style="text-align: center; max-height: 400px;">
                <img src="assets/img/coding-project.png" class="howwedoit" alt="image" style="max-height: 400px;
                max-height: 400px;
                padding-bottom: 50px;
                ">
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="features-box-one wow fadeInLeft" data-wow-delay=".1s">
                    <i class='xib xib-aws-logo bg-00d280' style="background-color: rgb(211, 137, 0); padding: 10px;"></i>
                    <h3><a routerLink="/what-we-use/aws">Amazon Web Services</a></h3>
                    <p>Amazon Web Services are the industry-leading cloud computing platform that provides servers, storage, networking, remote computing, email, mobile development, security, and many more features all under one umbrella. </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="features-box-one wow fadeInLeft" data-wow-delay=".2s">
                    <i class='xib xib-azure-logo bg-00d280' style="background-color: rgb(42, 42, 255); padding: 10px;"></i>
                    <h3><a routerLink="/what-we-use/azure">Microsoft Azure</a></h3>
                    <p>We also utilize Microsoft’s public cloud computing platform that provides a long-range of cloud services such as compute, analytics, storage, and networking. </p>        
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="features-box-one wow fadeInLeft" data-wow-delay=".3s">
                    <i class='xib xib-uipath-logo bg-ff612f' style="background-color: rgb(112, 162, 255); padding: 10px;"></i>
                    <h3><a routerLink="/what-we-use/uipath">UiPath</a></h3>
                    <p>We are fluent with UiPath when it comes to developing automation software. </p>
                </div>
            </div>
            <!-- <div class="col-lg-4 col-md-6">
                
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="features-box-one wow fadeInLeft" data-wow-delay=".4s">
                    <i class='xib xib-openshift-logo bg-ff612f' style="background-color: red; padding: 10px;"></i>
                    <h3><a routerLink="/what-we-use/openshift">Openshift</a></h3>
                    <p>We use OpenShift to develop and deploy applications at scale to one or more hosts whether they’re public-facing web applications or backend applications. 
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                
            </div> -->
        </div>
    </div>
</section>
                            
<!-- <section class="our-loving-clients ptb-100 bg-f4f5fe">
    <div class="container">
        <div class="section-title">
            <h2>Our Clients</h2>
        </div>
        <div class="clients-logo-list align-items-center">
            <div class="single-clients-logo wow fadeInUp" data-wow-delay=".2s">
                <a href="#">
                    <img src="assets/img/vodacom-logo.jpg" alt="image">
                </a>
            </div>
            
        </div>
    </div>
</section> -->

<!--
<section class="video-presentation-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Why choose us to watch this video know more</h2>
        </div>
        <div class="video-box">
            <img src="assets/img/video-bg.jpg" class="main-image" alt="image">
            <a href="https://www.youtube.com/watch?v=0gv7OC9L2s8" class="video-btn popup-youtube"><i class="bx bx-play"></i></a>
            <div class="shape1"><img src="assets/img/shape/shape1.png" alt="image"></div>
            <div class="shape2"><img src="assets/img/shape/shape2.png" alt="image"></div>
            <div class="shape3"><img src="assets/img/shape/shape3.png" alt="image"></div>
            <div class="shape4"><img src="assets/img/shape/shape4.png" alt="image"></div>
            <div class="shape5"><img src="assets/img/shape/shape5.png" alt="image"></div>
            <div class="shape6"><img src="assets/img/shape/shape6.png" alt="image"></div>
        </div>
        <div class="funfacts-inner">
            <div class="row">
                <div class="col-lg-3 col-6 col-sm-6">
                    <div class="single-funfacts funfact-style-two wow fadeInLeft" data-wow-delay=".2s">
                        <i class='bx bx-list-check'></i>
                        <h3><span class="odometer" data-count="900">00</span><span class="sign-icon">+</span></h3>
                        <p>Completed Projects</p>
                    </div>
                </div>
                <div class="col-lg-3 col-6 col-sm-6">
                    <div class="single-funfacts funfact-style-two wow fadeInLeft" data-wow-delay=".4s">
                        <i class='bx bx-smile'></i>
                        <h3><span class="odometer" data-count="850">00</span><span class="sign-icon">+</span></h3>
                        <p>Happy Clients</p>
                    </div>
                </div>
                <div class="col-lg-3 col-6 col-sm-6">
                    <div class="single-funfacts funfact-style-two wow fadeInLeft" data-wow-delay=".6s">
                        <i class='bx bx-grid-small'></i>
                        <h3><span class="odometer" data-count="120">00</span><span class="sign-icon">+</span></h3>
                        <p>Ongoing Projects</p>
                    </div>
                </div>
                <div class="col-lg-3 col-6 col-sm-6 wow fadeInLeft" data-wow-delay=".8s">
                    <div class="single-funfacts funfact-style-two">
                        <i class='bx bxs-award'></i>
                        <h3><span class="odometer" data-count="50">00</span><span class="sign-icon">+</span></h3>
                        <p>Winning Awards</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="contact-cta-box mwidth-1000 wow fadeInUp" data-wow-delay="1s">
            <h3>Have any question about us?</h3>
            <p>Don't hesitate to contact us.</p>
            <a routerLink="/contact" class="default-btn"><i class="bx bxs-edit-alt"></i> Contact Us<span></span></a>
        </div>
    </div>
    <div class="shape-map1"><img src="assets/img/map1.png" alt="image"></div>
    <div class="shape7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="shape9"><img src="assets/img/shape/shape9.png" alt="image"></div>
</section>

<section class="services-area ptb-100 bg-f4f6fc">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="services-image wow fadeInLeft" data-wow-delay=".3s">
                <div class="image">
                    <img src="assets/img/home-saas/feature5.png" alt="image">
                </div>
            </div>
            <div class="services-content it-service-content">
                <div class="content">
                    <div class="fun-facts-inner-content">
                        <h2>Build Beautiful Interface Into Your Application</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip.</p>
                        <ul>
                            <li><i class="bx bx-badge-check"></i>Professional Code</li>
                            <li><i class="bx bx-badge-check"></i>Unimited Video Call</li>
                            <li><i class="bx bx-badge-check"></i>Add Favourite contact</li>
                            <li><i class="bx bx-badge-check"></i>Camera Filter</li>
                            <li><i class="bx bx-badge-check"></i>Start Coding Format</li>
                        </ul>
                        <a routerLink="/about" class="default-btn black-btn"><i class="bx bxs-arrow-to-right"></i>Read More <span></span></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="shape9">
        <img src="assets/img/shape/shape9.png" alt="image">
    </div>
</section>

<section class="feedback-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Whats Our Clients Said About <span>Jexsa</span></h2>
        </div>
        <div class="feedback-slides owl-carousel owl-theme">
            <div class="single-feedback-item">
                <img src="assets/img/woman1.png" alt="image">
                <div class="feedback-desc">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p>
                    <div class="rating">
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                    </div>
                    <div class="client-info">
                        <h3>Sarah Taylor</h3>
                        <span>CEO at Envato</span>
                    </div>
                </div>
            </div>
            <div class="single-feedback-item">
                <img src="assets/img/woman2.png" alt="image">
                <div class="feedback-desc">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p>
                    <div class="rating">
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                    </div>
                    <div class="client-info">
                        <h3>Olivar Lucy</h3>
                        <span>CEO at EnvyTheme.com</span>
                    </div>
                </div>
            </div>
            <div class="single-feedback-item">
                <img src="assets/img/man1.png" alt="image">
                <div class="feedback-desc">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p>
                    <div class="rating">
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                    </div>
                    <div class="client-info">
                        <h3>Steven Smith</h3>
                        <span>CEO at Envato</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="pricing-area pt-100 pb-70 bg-f4f5fe">
    <div class="container">
        <div class="section-title">
            <h2>Choose The Pricing Plan</h2>
        </div>
        <div class="tab pricing-list-tab">
            <ul class="tabs">
                <li><a href="#"><i class="bx bxs-calendar-check"></i> Monthly</a></li>
                <li><a href="#"><i class="bx bxs-calendar-check"></i> Yearly</a></li>
            </ul>
            <div class="tab_content">
                <div class="tabs_item">
                    <div class="row">
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-pricing-table left-align">
                                <div class="pricing-header">
                                    <h3>Free</h3>
                                </div>
                                <div class="price">
                                    <sup>$</sup>0 <sub>/ monthly</sub>
                                </div>
                                <ul class="pricing-features">
                                    <li><i class="bx bxs-badge-check"></i> Up to 3 chat operators <span class="tooltips bx bxs-info-circle" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> 100 ChatBot Triggers</li>

                                    <li><i class="bx bxs-badge-check"></i> 24/7 Live Chat</li>

                                    <li><i class="bx bxs-badge-check"></i> Email Integration <span class="tooltips bx bxs-info-circle" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> Messenger Integration</li>

                                    <li><i class="bx bxs-badge-check"></i> Visitor Info</li>

                                    <li><i class="bx bxs-badge-check"></i> Mobile + Desktop Apps</li>

                                    <li><i class="bx bxs-badge-check"></i> Quick Responses <span class="tooltips bx bxs-info-circle" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> Drag & Drop Widgets</li>

                                    <li><i class="bx bxs-badge-check"></i> Visitor Notes <span class="tooltips bx bxs-info-circle" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> Google Analytics</li>
                                </ul>
                                <div class="btn-box">
                                    <a href="#" class="default-btn"><i class="bx bxs-hot"></i> Try It Free Now<span></span></a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6">
                            <div class="single-pricing-table left-align">
                                <div class="pricing-header">
                                    <h3>Starter</h3>
                                </div>
                                <div class="price">
                                    <sup>$</sup>49 <sub>/ monthly</sub>
                                </div>
                                <ul class="pricing-features">
                                    <li><i class="bx bxs-badge-check"></i> Up to 4 chat operators <span class="tooltips bx bxs-info-circle" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> 150 ChatBot Triggers</li>

                                    <li><i class="bx bxs-badge-check"></i> 24/7 Live Chat</li>

                                    <li><i class="bx bxs-badge-check"></i> Email Integration <span class="tooltips bx bxs-info-circle" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> Messenger Integration</li>

                                    <li><i class="bx bxs-badge-check"></i> Visitor Info</li>

                                    <li><i class="bx bxs-badge-check"></i> Mobile + Desktop Apps</li>

                                    <li><i class="bx bxs-badge-check"></i> Quick Responses <span class="tooltips bx bxs-info-circle" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> Drag & Drop Widgets</li>

                                    <li><i class="bx bxs-badge-check"></i> Visitor Notes <span class="tooltips bx bxs-info-circle" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> Google Analytics</li>
                                </ul>
                                <div class="btn-box">
                                    <a href="#" class="default-btn"><i class="bx bxs-hot"></i> Try It Free Now<span></span></a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6 offset-lg-0 offset-sm-3">
                            <div class="single-pricing-table left-align">
                                <div class="pricing-header">
                                    <h3>Professional</h3>
                                </div>
                                <div class="price">
                                    <sup>$</sup>79 <sub>/ monthly</sub>
                                </div>
                                <ul class="pricing-features">
                                    <li><i class="bx bxs-badge-check"></i> Up to 7 chat operators <span class="tooltips bx bxs-info-circle" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> 250 ChatBot Triggers</li>

                                    <li><i class="bx bxs-badge-check"></i> 24/7 Live Chat</li>

                                    <li><i class="bx bxs-badge-check"></i> Email Integration <span class="tooltips bx bxs-info-circle" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> Messenger Integration</li>

                                    <li><i class="bx bxs-badge-check"></i> Visitor Info</li>

                                    <li><i class="bx bxs-badge-check"></i> Mobile + Desktop Apps</li>

                                    <li><i class="bx bxs-badge-check"></i> Quick Responses <span class="tooltips bx bxs-info-circle" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> Drag & Drop Widgets</li>

                                    <li><i class="bx bxs-badge-check"></i> Visitor Notes <span class="tooltips bx bxs-info-circle" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> Google Analytics</li>
                                </ul>
                                <div class="btn-box">
                                    <a href="#" class="default-btn"><i class="bx bxs-hot"></i> Try It Free Now<span></span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tabs_item">
                    <div class="row">
                        <div class="col-lg-4 col-md-6">
                            <div class="single-pricing-table left-align">
                                <div class="pricing-header">
                                    <h3>Free</h3>
                                </div>
                                <div class="price">
                                    <sup>$</sup>0 <sub>/ yearly</sub>
                                </div>
                                <ul class="pricing-features">
                                    <li><i class="bx bxs-badge-check"></i> Up to 3 chat operators <span class="tooltips bx bxs-info-circle" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> 100 ChatBot Triggers</li>

                                    <li><i class="bx bxs-badge-check"></i> 24/7 Live Chat</li>

                                    <li><i class="bx bxs-badge-check"></i> Email Integration <span class="tooltips bx bxs-info-circle" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> Messenger Integration</li>

                                    <li><i class="bx bxs-badge-check"></i> Visitor Info</li>

                                    <li><i class="bx bxs-badge-check"></i> Mobile + Desktop Apps</li>

                                    <li><i class="bx bxs-badge-check"></i> Quick Responses <span class="tooltips bx bxs-info-circle" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> Drag & Drop Widgets</li>

                                    <li><i class="bx bxs-badge-check"></i> Visitor Notes <span class="tooltips bx bxs-info-circle" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> Google Analytics</li>
                                </ul>
                                <div class="btn-box">
                                    <a href="#" class="default-btn"><i class="bx bxs-hot"></i> Try It Free Now<span></span></a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6">
                            <div class="single-pricing-table left-align">
                                <div class="pricing-header">
                                    <h3>Starter</h3>
                                </div>
                                <div class="price">
                                    <sup>$</sup>79 <sub>/ yearly</sub>
                                </div>
                                <ul class="pricing-features">
                                    <li><i class="bx bxs-badge-check"></i> Up to 4 chat operators <span class="tooltips bx bxs-info-circle" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> 150 ChatBot Triggers</li>

                                    <li><i class="bx bxs-badge-check"></i> 24/7 Live Chat</li>

                                    <li><i class="bx bxs-badge-check"></i> Email Integration <span class="tooltips bx bxs-info-circle" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> Messenger Integration</li>

                                    <li><i class="bx bxs-badge-check"></i> Visitor Info</li>

                                    <li><i class="bx bxs-badge-check"></i> Mobile + Desktop Apps</li>

                                    <li><i class="bx bxs-badge-check"></i> Quick Responses <span class="tooltips bx bxs-info-circle" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> Drag & Drop Widgets</li>

                                    <li><i class="bx bxs-badge-check"></i> Visitor Notes <span class="tooltips bx bxs-info-circle" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> Google Analytics</li>
                                </ul>
                                <div class="btn-box">
                                    <a href="#" class="default-btn"><i class="bx bxs-hot"></i> Try It Free Now<span></span></a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                            <div class="single-pricing-table left-align">
                                <div class="pricing-header">
                                    <h3>Professional</h3>
                                </div>
                                <div class="price">
                                    <sup>$</sup>99 <sub>/ yearly</sub>
                                </div>
                                <ul class="pricing-features">
                                    <li><i class="bx bxs-badge-check"></i> Up to 7 chat operators <span class="tooltips bx bxs-info-circle" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> 250 ChatBot Triggers</li>

                                    <li><i class="bx bxs-badge-check"></i> 24/7 Live Chat</li>

                                    <li><i class="bx bxs-badge-check"></i> Email Integration <span class="tooltips bx bxs-info-circle" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> Messenger Integration</li>

                                    <li><i class="bx bxs-badge-check"></i> Visitor Info</li>

                                    <li><i class="bx bxs-badge-check"></i> Mobile + Desktop Apps</li>

                                    <li><i class="bx bxs-badge-check"></i> Quick Responses <span class="tooltips bx bxs-info-circle" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> Drag & Drop Widgets</li>

                                    <li><i class="bx bxs-badge-check"></i> Visitor Notes <span class="tooltips bx bxs-info-circle" title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                                    <li><i class="bx bxs-badge-check"></i> Google Analytics</li>
                                </ul>
                                <div class="btn-box">
                                    <a href="#" class="default-btn"><i class="bx bxs-hot"></i> Try It Free Now<span></span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="faq-area ptb-100">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-12">
                <div class="faq-accordion">
                    <h2>Get to know about <span>Jexsa</span></h2>
                    <div class="accordion" id="faqAccordion">
                        <div class="accordion-item">
                            <div class="accordion-header">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    What access do I have on the free plan? <i class="bx bx-plus"></i>
                                </button>
                            </div>
                            <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#faqAccordion">
                                <div class="accordion-body">
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    What access do I have on a free trial? <i class="bx bx-plus"></i>
                                </button>
                            </h2>
                            <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                <div class="accordion-body">
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    Does the price go up as my team gets larger? <i class="bx bx-plus"></i>
                                </button>
                            </h2>
                            <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                <div class="accordion-body">
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                    How can I cancel/pause my subscription? <i class="bx bx-plus"></i>
                                </button>
                            </h2>
                            <div id="collapseFour" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                <div class="accordion-body">
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                    Can I pay via an invoice? <i class="bx bx-plus"></i>
                                </button>
                            </h2>
                            <div id="collapseFive" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                <div class="accordion-body">
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-5 col-md-12">
                <div class="faq-image wow fadeInUp" data-wow-delay=".2s">
                    <img src="assets/img/faq-img1.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>

<section class="our-loving-clients ptb-100 bg-f4f5fe">
    <div class="container">
        <div class="section-title">
            <h2>Our Loving Clients</h2>
        </div>
        <div class="clients-logo-list align-items-center">
            <div class="single-clients-logo wow fadeInUp" data-wow-delay=".2s">
                <a href="#">
                    <img src="assets/img/clients-image/img1.png" alt="image">
                </a>
            </div>
            <div class="single-clients-logo wow fadeInUp" data-wow-delay=".4s">
                <a href="#">
                    <img src="assets/img/clients-image/img2.png" alt="image">
                </a>
            </div>
            <div class="single-clients-logo wow fadeInUp" data-wow-delay=".6s">
                <a href="#">
                    <img src="assets/img/clients-image/img3.png" alt="image">
                </a>
            </div>
            <div class="single-clients-logo wow fadeInUp" data-wow-delay=".8s">
                <a href="#">
                    <img src="assets/img/clients-image/img4.png" alt="image">
                </a>
            </div>
            <div class="single-clients-logo wow fadeInUp" data-wow-delay="1s">
                <a href="#">
                    <img src="assets/img/clients-image/img5.png" alt="image">
                </a>
            </div>
            <div class="single-clients-logo wow fadeInUp" data-wow-delay="1.2s">
                <a href="#">
                    <img src="assets/img/clients-image/img6.png" alt="image">
                </a>
            </div>
            <div class="single-clients-logo wow fadeInUp" data-wow-delay="1.4s">
                <a href="#">
                    <img src="assets/img/clients-image/img7.png" alt="image">
                </a>
            </div>
            <div class="single-clients-logo wow fadeInUp" data-wow-delay="1.6s">
                <a href="#">
                    <img src="assets/img/clients-image/img8.png" alt="image">
                </a>
            </div>
            <div class="single-clients-logo wow fadeInUp" data-wow-delay="1.8s">
                <a href="#">
                    <img src="assets/img/clients-image/img9.png" alt="image">
                </a>
            </div>
        </div>
    </div>
</section>

<section class="free-trial-area pb-100 bg-f4f5fe">
    <div class="container">
        <div class="free-trial-content">
            <h2>We always try to be the best support to you as possible</h2>
            <p>Qualify your leads & recognize the value of word your customer will love you</p>
            <a routerLink="/contact" class="default-btn"><i class="bx bxs-hot"></i> Contact Us<span></span></a>
        </div>
    </div>
    <div class="shape10"><img src="assets/img/shape/shape10.png" alt="image"></div>
    <div class="shape11"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape12"><img src="assets/img/shape/shape11.png" alt="image"></div>
    <div class="shape13"><img src="assets/img/shape/shape12.png" alt="image"></div>
</section> -->