import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'wwu-openshift',
  templateUrl: './openshift.component.html',
  styleUrls: ['./openshift.component.scss']
})
export class OpenshiftComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
