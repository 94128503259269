<!-- <div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Microsoft Azure</h2>
        </div>
    </div>
</div> -->

<div class="service-details ptb-100">
    <div class="container">
        <div class="service-details-content">
            <h3>Microsoft Azure</h3>
            <img src="assets/img/azure.svg" alt="image" class="pageImage">
            <p>
                The Azure cloud platform offers more than 200 products and cloud services. It has a range of cloud services that include compute, analytics, storage, and networking. They have a pay-as-you-go charging method and offer flexibility when it comes to choosing preferred tools. They offer PaaS, SaaS, IaaS, and serverless cloud computing. 
                <br>Similar to AWS, Azure’s cloud services span across a dozen different categories including mobile, web, storage, analytics, networking, integration, content delivery networks, DevOps, and security. 

            </p>
        </div>
    </div>
</div>

