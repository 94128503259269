import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './components/layouts/navbar/navbar.component';
import { PreloaderComponent } from './components/layouts/preloader/preloader.component';
import { FooterComponent } from './components/layouts/footer/footer.component';
import { HomeFourComponent } from './components/pages/home-four/home-four.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { ChattingComponent } from './components/common/chatting/chatting.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    PreloaderComponent,
    FooterComponent,
    HomeFourComponent,
    ContactComponent,
    ChattingComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    GoogleTagManagerModule.forRoot({
      id: 'GTM-5WQ8XGK',
    })
  ],
  providers: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
