<!-- <div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Application Support</h2>
        </div>
    </div>
</div> -->

<div class="service-details ptb-100">
    <div class="container">
        <!-- <div class="service-details-image">
            <img src="assets/img/data-entry.png" alt="image"  style="max-height: 400px;">
        </div> -->
        <div class="service-details-content">
            <h3>Application Support</h3>
            <img src="assets/img/data-entry.png" alt="image"  class="pageImage"/>
            <p>
                XIB Solutions offers three tiers of support: L1, L2, and L3. 
            </p>
            <p>
                L1 support services include solving our client’s problems via chat, phone, or email communications. This is the first line of support. Mostly, L1 support involves engaging with the client, knowing their challenges, generating tickets for them, and troubleshooting their problems remotely. 
            </p>
            <p>
                L2 support handles the ticket that the L1 support routes to it. This support team can also generate tickets for any problems they notice. L2 supporters have more skill and experience in solving complicated problems.
            </p>
            <p>
                L3 support involves onsite customer support. A development team deals with technical problems by using product design, code, and requirements to pinpoint the root cause and resolve it. 
            </p>
            <p>
                Additionally, we also provide a help desk service. 
            </p>
            <p>
                We support many of the solutions that we have developed. 
            </p>
            <p>
                An important aspect of the application support service is documenting the problem resolution process and adjusting it so that the next incident can be prevented or handled more efficiently. Ideally, we’d solve the issue before it gets reported by the end-user. 
            </p>
            <p>
                As part of our application support, we provide the following services:
            </p>
            <h4>User Support</h4>
            <ul>
                <li>Problem Resolution</li>
                <li>Change Requests</li>
                <li>Contextual Help (How-to)</li>
            </ul>
            <h4>Application Support</h4>
            <ul>
                <li>Software Release Management</li>
                <li>Database Maintenance</li>
                <li>Enhancements</li>
                <li>Deployments</li>
                <li>Root Cause Analysis</li>
                <li>Escalations</li>
            </ul>
            <h4>Operations Support</h4>
            <ul>
                <li>Capacity Planning</li>
                <li>Daily Health Checks</li>
                <li>Deployments</li>
                <li>Software Configuration</li>
                <li>Application Maintenance</li>
            </ul>
            <h4>End User Support</h4>
            <ul>
                <li>Training Manuals</li>
                <li>Tutorial Material</li>
                <li>Live Training</li>
                <li>Online Help</li>
            </ul>
        </div>
    </div>
</div>

