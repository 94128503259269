import { Component, OnInit } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

import { Router, NavigationStart, NavigationCancel, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { filter } from 'rxjs/operators';
declare let $: any;

declare let gtag: Function;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class AppComponent implements OnInit {
    location: any;
    routerSubscription: any;

    constructor(private gtmService: GoogleTagManagerService, private router: Router) {
    }

    ngOnInit(){
        this.gtmService.addGtmToDom();
        this.recallJsFuntions();
        // this.setUpAnalytics();
    }

    recallJsFuntions() {
        this.router.events
        .subscribe((event) => {
            if ( event instanceof NavigationStart ) {
                $('.preloader-area').fadeIn(1);
            }
        });
        this.routerSubscription = this.router.events
        .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
        .subscribe(event => {
            $.getScript('../assets/js/main.js');
            $.getScript('../assets/js/conversation.js');
            $('.preloader-area').fadeOut('slow');
            this.location = this.router.url;
            if (!(event instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0);
        });
    }
    // setUpAnalytics() {
    //     this.router.events.pipe(filter(event => event instanceof NavigationEnd))
    //         .subscribe((event: NavigationEnd) => {
    //             gtag('config', 'G-TG0JXMG316',
    //                 {
    //                     page_path: event.urlAfterRedirects
    //                 }
    //             );
    //         });
    // }
}
