import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeFourComponent } from './components/pages/home-four/home-four.component';
import { OutsourcedDevelopmentComponent } from './components/pages/what-we-do/outsourced-development.component';
import { IntegrationMiddlewareComponent } from './components/pages/what-we-do/integration-middleware.component';
import { WebApplicationsComponent } from './components/pages/what-we-do/web-applications.component';
import { ApplicationSupportComponent } from './components/pages/what-we-do/application-support.component';
import { ManualAutomatedTestingComponent } from './components/pages/what-we-do/manual-automated-testing.component';
import { ConsultationMentoringComponent } from './components/pages/what-we-do/consultation-mentoring.component';
import { AgileDevelopmentComponent } from './components/pages/how-we-do-it/agile-development.component';
import { DevelopmentPartnershipComponent } from './components/pages/how-we-do-it/development-partnership.component';
import { QualityAssuranceComponent } from './components/pages/how-we-do-it/quality-assurance.component';
import { ResponsibleServiceComponent } from './components/pages/how-we-do-it/responsible-service.component';
import { AwsComponent } from './components/pages/what-we-use/aws.component';
import { AzureComponent } from './components/pages/what-we-use/azure.component';
import { UiPathComponent } from './components/pages/what-we-use/uipath.component';
import { OpenshiftComponent } from './components/pages/what-we-use/openshift.component';

const routes: Routes = [
    {path: '', redirectTo: '/home', pathMatch: 'full'},
    {path: 'home', component: HomeFourComponent },
    {path: 'what-we-do/outsourced-development', component: OutsourcedDevelopmentComponent},
    {path: 'what-we-do/integration-middleware', component: IntegrationMiddlewareComponent},
    {path: 'what-we-do/web-applications', component: WebApplicationsComponent},
    {path: 'what-we-do/application-support', component: ApplicationSupportComponent},
    {path: 'what-we-do/manual-automated-testing', component: ManualAutomatedTestingComponent},
    {path: 'what-we-do/consultation-mentoring', component: ConsultationMentoringComponent},
    {path: 'how-we-do-it/agile-development', component: AgileDevelopmentComponent},
    {path: 'how-we-do-it/development-partnership', component: DevelopmentPartnershipComponent},
    {path: 'how-we-do-it/quality-assurance', component: QualityAssuranceComponent},
    {path: 'how-we-do-it/responsible-service', component: ResponsibleServiceComponent},
    {path: 'what-we-use/aws', component: AwsComponent},
    {path: 'what-we-use/azure', component: AzureComponent},
    {path: 'what-we-use/uipath', component: UiPathComponent},
    {path: 'what-we-use/openshift', component: OpenshiftComponent},
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy', useHash: true })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
