import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'wwd-consultation-mentoring',
  templateUrl: './consultation-mentoring.component.html',
  styleUrls: ['./consultation-mentoring.component.scss']
})
export class ConsultationMentoringComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
