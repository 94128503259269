<footer class="footer-area">
    <div class="divider"></div>
    <div class="container">
        <div class="row">
            <!-- <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <div class="logo">
                        <a routerLink="/"><img src="assets/img/xib-logo.png" alt="image"></a>
                    </div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                </div>
            </div> -->
            <!-- <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Company</h3>
                    <ul class="services-list">
                        <li><a routerLink="/">About Us</a></li>
                        
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Support</h3>
                    <ul class="support-list">
                        
                        <li><a routerLink="/contact">Contact Us</a></li>
                    </ul>
                </div>
            </div> -->
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Contact Info</h3>
                    <ul class="footer-contact-info">
                        <!-- <li>Location: <a href="https://goo.gl/maps/MQ78iGP5g9VgZcJ38" target="_blank">Our Address</a></li> -->
                        <li>Email: <a href="mailto:info@xibsolutions.com">info@xibsolutions.com</a></li>
                        <!-- <li>Phone: <a href="tel:+12345678">+27 (82) 111111</a></li> -->
                    </ul>
                    <ul class="social">
                        <li><a href="https://www.facebook.com/pages/XIB-Solutions/239035612911904?rf=506593836101795" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                        <!-- <li><a href="" target="_blank"><i class="bx bxl-twitter"></i></a></li> -->
                        <li><a href="https://www.linkedin.com/company-beta/17897537/" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                        <!-- <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li> -->
                    </ul>
                </div>
            </div>
        </div>
        <div class="copyright-area">
            <p>Copyright @2017 XIB Solutions, Inc. All Rights Reserved.</p>
            <!-- <p>Copyright @2021 Jexsa. Designed by <a href="http://envytheme.com/" target="_blank">EnvyTheme</a></p> -->
        </div>
    </div>
</footer>

<div class="go-top"><i class='bx bx-chevron-up'></i></div>