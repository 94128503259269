<!-- <div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Amazon Web Services</h2>
        </div>
    </div>
</div> -->

<div class="service-details ptb-100">
    <div class="container">
        <div class="service-details-content">
            <h3 class="pageHeader">Amazon Web Services</h3>
            <img src="assets/img/aws.svg" alt="image" class="pageImage" style="padding: 20px; max-height: 170px;">
            <p>
                AWS has proven to be a comprehensive and fast-evolving cloud computing platform provided by Amazon. It includes a combination of IaaS, PaaS, and SaaS offerings. AWS offers compute power, database storage, and content delivery services. <br>
                Its suite has more than 100 different services such as data management, development tools, analytics, AI, mobile development, networking, monitoring, security, storage databases, and hybrid cloud. 
            </p>
        </div>
    </div>
</div>

